import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { Contract } from "@ethersproject/contracts";
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { Navigate } from 'react-router-dom';

import ProjectItem from '../item';
import '../../../scss/components/form.scss'
import Button from '../../../components/button';
import { addressNFT } from '../../../utils/constants';
import abiNFT from '../../../utils/abi/contractNFT.json';
import PageTitle from '../../../components/pagetitle';


Project.propTypes = {
    data: PropTypes.array,
};

function Project(props) {
    const { data } = props;

    const { library, active, account, activate, } = useWeb3React();

    const [items, setItems] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [itemNotFound, setItemNotFound] = useState(false);

    useEffect(() => {
        async function connectWeb3() {
            if (!library) {
                setRedirect(true);
            }

            if (!active) {
                activate(new InjectedConnector({ supportedNetworks: [10001] }));
            }
        }
        connectWeb3();
    }, [active]);



    useEffect(() => {
        async function getData() {
            if (!items.length) {

                if (!active) {
                    activate(new InjectedConnector({ supportedNetworks: [10001] }));
                }

                const contractNFT = new Contract(addressNFT, abiNFT, library?.getSigner());

                // let data = await contractNFT.walletOfOwner(account ? account : localStorage.getItem('account'));

                // console.log(contractNFT)
                // let data = await contractNFT.getAllByOwner(account);

                // console.log(data)

                let data = [];

                for(let i = 10000; i < 11111; i++){
                    data.push(i);
                }


                if (!data.length) {
                    setItemNotFound(true);
                }

                setItems(data);
            }
        }

        getData();

    }, []);



    const [visible, setVisible] = useState(16);

    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 8);
    }

    return (
        <section className="tf-section tf-project home-2 nft-item">

            {redirect ? <Navigate to='/' /> : null}

            {itemNotFound ?

                <div className="breadcrumbs" data-aos="zoom-in" data-aos-duration="800">
                    <h3>YOU DON'T HAVE NFT 🚫</h3>
                </div> :

                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="widget widget-search" data-aos="fade-in" data-aos-duration="800">
                                <form action="#">
                                    <input type="text" placeholder="Search" required="" />
                                    <button><i className="fal fa-search"></i></button>
                                </form>
                            </div>
                        </div>

                        <div className="col-md-8 z-index">
                            <div className="seclect-box" data-aos="fade-in" data-aos-duration="800">
                                {/* <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        All Categories
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#">
                                            <li data-toggle="modal" data-target="#delete_client">Forgotten Samurai</li>
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#">
                                            <li data-toggle="modal" data-target="#edit_client">Star Batter</li>
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#">
                                            <li data-toggle="modal" data-target="#edit_client">Calvary Deneral</li>
                                        </Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown> */}

                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        Default sorting
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#">
                                            <li data-toggle="modal" data-target="#delete_client">Sort by Popularity</li>
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#">
                                            <li data-toggle="modal" data-target="#edit_client">Sort by Latest</li>
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#">
                                            <li data-toggle="modal" data-target="#edit_client">Sort by View</li>
                                        </Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>

                        {
                            items.slice(0, visible).map(item => (
                                <div key={item.toString()} className="col-md-3">
                                    <ProjectItem item={item} />
                                </div>
                            ))
                        }
                        {
                            visible < 100 &&
                            <div className="col-md-12">
                                <div className="btn-about center m-t16" data-aos="fade-up" data-aos-delay="300" data-aos-duration="800">
                                    <Button title='Load More' path='#' onClick={showMoreItems} />
                                </div>
                            </div>
                        }

                    </div>
                </div>
            }
        </section>
    );
}

export default Project;