export const REACT_APP_RPC_URL = process.env.REACT_APP_RPC_URL || "";
export const REACT_APP_NETWORK_ID = process.env.REACT_APP_NETWORK_ID || 1;

export const addressNFT = "0xE5dF2FD5b6DA393727c928842A181d539CD25e91";
// 0x121C1F8b403ea494930cBA4389fD4460e3e1cd19
export const uriIMAGE = "QmWdBV8sCZ7k7yFhUWnLPqUSkGJKuFaw5Qqs2Y2KJZohgt";

export const uriMETADATA = "QmTd6wo7DNEBKBgPVx9P6kx23BXTL95gcE2fgty1L5oqWN";

export const symbolNetwork = 'SC20';

export const nameNetwork = 'ShineChain';

export const idNetwork = '11221';





