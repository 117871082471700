import React, { useEffect, useState } from 'react';
import { Contract } from "@ethersproject/contracts";
import { InjectedConnector } from '@web3-react/injected-connector';
import { useWeb3React } from '@web3-react/core';

import './styles.scss';


import loadingGif from '../../../assets/gif.gif'

import { addressNFT } from '../../../utils/constants';
import abiNFT from '../../../utils/abi/contractNFT.json';


function Card(props) {
    const { active, account, activate, library } = useWeb3React();

    const [amountMint, setAmountMint] = useState(1);

    const [loading, setLoading] = useState(false);

    const [statusDone, setStatusDone] = useState(false);
    const [statusWarn, setStatusWarn] = useState(false);
    const [statusError, setStatusError] = useState(false);

    const [statusErrorRPC, setStatusErrorRPC] = useState(false);

    const [punks, setPunks] = useState([]);



    useEffect(() => {
        async function init() {
            // let { data } = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=moonbeam&vs_currencies=usd');

            // console.log(typeof data?.moonbeam.usd)

            // setPrice((data?.moonbeam.usd * 7.5).toFixed(2));

            if (!active) {
                activate(new InjectedConnector({ supportedNetworks: [11221] }));
            }
            // if (active && account) {
            //     localStorage.setItem('account', account);
            // }
            const contractNFT2 = new Contract(addressNFT, abiNFT, library?.getSigner());

            if(account){
                const ress = await contractNFT2.walletOfOwner(account);
                setPunks(ress.toString().split(',') === '' ? [] : ress.toString().split(','))
            }
            
        }
        init();
    }, []);

    
    useEffect(() => {
        async function init() {
            const contractNFT2 = new Contract(addressNFT, abiNFT, library?.getSigner());

            if(account){
                const ress = await contractNFT2.walletOfOwner(account);
                setPunks(ress.toString().split(',') === '' ? [] : ress.toString().split(','))
            }
        }
        init();
    }, [account, addressNFT, library, abiNFT]);


    const contractNFT = new Contract(addressNFT, abiNFT, library?.getSigner());

    async function minter() {
        const ress = await contractNFT.walletOfOwner(account);
      
        setPunks(ress.toString().split(',') === '' ? [] : ress.toString().split(','))

        setStatusDone(false);
        setStatusError(false);

        setStatusErrorRPC(false);

        setLoading(true);
        setStatusWarn(true);

        if (!active) {
            activate(new InjectedConnector({ supportedNetworks: [11221] }));
        }

        try {

            let res;

            let valueMint = amountMint * 50000000000000000000;

            res = await contractNFT.mint(amountMint.toString(), {
                // The address to execute the call as
                from: account,

                // The maximum units of gas for the transaction to use
                // gasLimit: 310000,

                gasLimit: 3400000,
            
                value: valueMint.toString()
            });
            
            let tx = await res.wait();

            setLoading(false);
            setStatusWarn(false);
            setStatusDone(true);

        } catch (error) {
            setStatusErrorRPC(true);
            setLoading(false);

        }

    }

    return (
        <div className="team-box flex-mint">
            <div className="image-mint">
                <img src={loadingGif} alt="punks"  style={{ marginBottom: 20 }}/>
            </div>

            
                <>
                    <div className="content">
                        <div className="mint-title">
                           {amountMint <= 1 ? 50 : (amountMint * 50)} SC20
                        </div>

                    </div>

                    {statusWarn ?
                        <div className="box-mint-warn">
                            <span>minting...</span>
                        </div>
                        : null}

                    {statusDone ?
                        <div className="box-mint-done">
                            <span>mint successfully!</span>
                        </div>
                        : null}

                    {statusError ?
                        <div className="box-mint-error">
                            <span>Mint Error</span>
                        </div>
                        : null}

                    <div className="flex-mint-2">
                    
                        <input type="number" className="input-mint" defaultValue={amountMint} onChange={e => setAmountMint(e.target.value)} />
               

                        <span className={`tf-button-st2 btn-effect ${false ? `btn-effect-3` : `btn-effect-2`} `}  onClick={() => minter(amountMint)}>
                            <span className="effect">mint</span>
                        </span>
                    </div>
                </>
            
                <div className="refe" onClick={() => navigator.clipboard.writeText(`0xE5dF2FD5b6DA393727c928842A181d539CD25e91`)} style={{ marginTop: 30 }}>
                    <div className="mint-title-ref" onClick={() => navigator.clipboard.writeText(`0xE5dF2FD5b6DA393727c928842A181d539CD25e91`)}>⬇️ NFT CONTRACT ⬇️</div>

                    <div className="mint-title-ref-link" onClick={() => navigator.clipboard.writeText(`0xE5dF2FD5b6DA393727c928842A181d539CD25e91`)}>click to copy 💾</div>

                    <div className="mint-title-ref-link2" onClick={() => navigator.clipboard.writeText(`0xE5dF2FD5b6DA393727c928842A181d539CD25e91`)}>0xE5dF2FD5b6DA393727c928842A181d539CD25e91</div>

                </div>

            {
            
                punks.length && !(punks.length === 1 && punks[0] === "") ?
                <>
                    <div className="name-text-rarity" style={{ fontSize: 24, textAlign: 'center',  marginTop: 60  }}>
                        MY SHINE PUNKS
                    </div>
                    

                    <div className="team-box flex-mint-99">
                    
                        {punks.map(item => (
                        // <div className="image-my-mint" key={item}>
                        //     {/* <img src={loadingGif} alt="punks"  style={{ marginBottom: 20 }}/> */}
                        //     <img src={`https://storageapi.fleek.co/c6cbd15f-766d-42a0-8dcf-7dfde26c1275-bucket/punks/${item.toString()}.png`} alt="crybox" className="img-disco" />
                        // </div>

                        <div className="img-box-internal-2 margin-item" key={item}>
                            <img src={`https://storageapi.fleek.co/c6cbd15f-766d-42a0-8dcf-7dfde26c1275-bucket/punks/${item.toString()}.png`} alt="punk" className="img-disco" />

                            <div className="title-container-item">

                                <div className="name-text-rarity" style={{ fontSize: 16 }}>
                                    Shine Punks
                                </div>

                                <div className="name-text" style={{ fontSize: 12, marginTop: 22 }}>
                                    #{item.toString()}
                                
                                </div>

                            </div>
                        </div>
                        ))}
                    
                    </div>
                </>

                : null
            }


        </div>
    );
}

export default Card;