import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';



ProjectItem.propTypes = {
    item: PropTypes.object,
};

function ProjectItem(props) {

    const { item } = props;

    return (
        <div className="img-box-internal">

            <img src={`https://storageapi.fleek.co/c6cbd15f-766d-42a0-8dcf-7dfde26c1275-bucket/punks/${item.toString()}.png`} alt="crybox" className="img-disco" />

            <div className="title-container-item">

                <div className="name-text-rarity">
                    Shine Punks
                </div>

                <div className="name-text" style={{ fontSize: 12, marginTop: 25 }}>
                    {/* #{item.toString()} */}
                    Degen Club
                </div>

            </div>


        </div>
    );
}

export default ProjectItem;