import React, { useState, useEffect } from 'react';
import {
    useScrollSection
} from "react-scroll-section";
import { useWeb3React } from '@web3-react/core';

import { Link, NavLink } from 'react-router-dom';
import menus from '../../pages/menu';

import './styles.scss';
import logo from '../../assets/images/logo/logo.png'
import { InjectedConnector } from '@web3-react/injected-connector';
// import Button from '../button';

const Header = () => {

    const { active, account, activate } = useWeb3React();

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };


    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index);
    };

    const homeSection = useScrollSection('home');
    const mintSection = useScrollSection('mint');
    const aboutSection = useScrollSection('about');
    const roadmapSection = useScrollSection('roadmap');

    function checkRoute(id) {
        if (id === 1) {
            return homeSection.onClick;
        }

        if (id === 2) {
            return mintSection.onClick;
        }

        if (id === 3) {
            return aboutSection.onClick;
        }

        if (id === 4) {
            return roadmapSection.onClick;
        }

        return homeSection.onClick;
    };

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container">
                <div id="site-header-inner">
                    <div className="header__logo">
                        <NavLink to="/"><img src={logo} className="header__logo2 header__logo"  alt="ultrabex" /></NavLink>
                    </div>
                    <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`} style={{ marginTop: 30 }} >
                        <ul id="menu-primary-menu" className="menu">
                            {/* <li key={654} className={`menu-item`}>
                                <a href="#" className={`menu-item`} target="_blank" rel="noopener noreferrer">
                                    HOME
                                </a>
                            </li> */}
                            {
                                menus.map((data, idx) => (
                                    <li key={idx} onClick={checkRoute(data.id)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}
                                    >
                                        <Link to={data.links}>{data.name}</Link>
                                        {
                                            data.namesub &&
                                            <ul className="sub-menu">
                                                {
                                                    data.namesub.map((submenu) => (
                                                        <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}>{submenu.sub}</NavLink></li>
                                                    ))
                                                }
                                            </ul>
                                        }

                                    </li>
                                ))
                            }

                            <li key={888} className={`menu-item`}>
                                <a href="https://twitter.com/shinescanx" className={`menu-item`} target="_blank" rel="noopener noreferrer">
                                    TWITTER
                                </a>
                            </li>

                            <li key={777} className={`menu-item`}>
                                <a href="https://t.me/shinechainOfficialBrazil" className={`menu-item`} target="_blank" rel="noopener noreferrer">
                                    TELEGRAM
                                </a>
                            </li>

                            {/* <li key={999} className={`menu-item`}>
                                <a href="#" className={`menu-item`}  rel="noopener noreferrer">
                                    MARKETPLACE
                                </a>
                            </li> */}


                            {/* <li key={999} className={`menu-item`}>
                                <a href="https://loopipay.com/invite/NIYUNK?utm_source=mgm&utm_medium=referral&utm_campaign=NIYUNK" className={`menu-item`} target="_blank" rel="noopener noreferrer">
                                    BUY SAMA
                                </a>
                            </li> */}
                        </ul>
                    </nav>

                    <button
                        className="tf-button btn-effect"
                        rel="noopener noreferrer"
                        onClick={() => activate(new InjectedConnector({ supportedNetworks: [10001] }))}
                        style={{ padding: '9px 12px'}}
                    >
                        <span className="boder-fade"></span>
                        <span className="effect"   style={{ fontSize: 10 }}>
                            {active ? '🟢 ' : '🔴 '}

                            {active ?
                                `${account.substring(0, 5)}...${account.substring(account.length, account.length - 4)}`
                                : ' CONNECT'}
                        </span>
                    </button>

                    <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                </div>
            </div>
        </header>
    );
}

export default Header;